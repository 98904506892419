:root {
    --primary-color: #1890ff;
    --secondary-color: #40a9ff;
    --background-color: #ffffff;
    --text-color: #000000;
    --text-color-light: #ffffff;
    --border-color: #d9d9d9;
    --error-color: #ff4d4f;
    --success-color: #52c41a;
    --warning-color: #faad14;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  