.user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .user-profile .ant-descriptions {
    width: 100%;
    max-width: 800px;
  }